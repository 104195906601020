<template>
  <div v-if="init" class="products">
    <h1>Products</h1>

    <div class="controls">
      <div class="search">
        <input type="text" placeholder="Search" v-model="search" />
      </div>
      <div class="meta">
        <span
          >Showing: {{ getProductsBySearch(search).length }} /
          {{ productCount }}
        </span>
      </div>
    </div>

    <div class="admin-table products-table">
      <div class="table-header">
        <span class="prodno label">ID</span>
        <span class="name label">Name</span>
        <span class="description label">Description</span>
        <span class="unit label">Unit</span>
        <span class="stock label">Stock</span>
        <span class="group label">Group</span>
        <span class="category label">Category</span>
      </div>
      <div v-for="prod in getProductsBySearch(search)" :key="prod.id">
        <span class="prodno">{{ prod.vismaId }}</span>
        <span class="name">{{ prod.name }}</span>
        <span class="description">{{ prod.description }}</span>
        <span class="unit">{{ prod.unit }}</span>
        <span class="stock">{{ prod.stock }}</span>
        <span class="group">{{ prod.group }}</span>
        <span class="category">{{ prod.category }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      createEmail: "",
      loading: false,
      message: "",
      search: "",
    };
  },
  mounted() {},
  created() {},
  computed: {
    ...mapState({
      productList: (state) => state.products.productList,
      init: (state) => state.settings.init,
    }),
    ...mapGetters("products", ["getProductsBySearch", "productCount"]),
  },
};
</script>
